import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosRequestConfiguration } from './config';
import cookie from 'react-cookies';

const tokenFunction = () => {
    const token = sessionStorage.getItem('token_econocom');

    return `Bearer ${token}`;
};
const checkNumberFunction = () => {
    const checkNumber = sessionStorage.getItem('checkNumber');
    return checkNumber;
};

const initialization = (configs: AxiosRequestConfig): AxiosInstance => {
    const axiosInstance = axios.create(configs);

    axiosInstance.interceptors.request.use(async (request) => {
        if (request.url?.includes('upsert')) {
            request.headers.Authorization = tokenFunction();
            request.headers.RequestId = checkNumberFunction();
            request.headers.SCH_ID = 'N_IT_SERVICE_PROV';
            return request;
        }
        if (!request.url?.includes('login') && !request.url?.includes('verification')) {
            request.headers.Authorization = tokenFunction();
            request.headers.SCH_ID = 'N_IT_SERVICE_PROV';
            return request;
        }
        return request;
    });
    return axiosInstance;
};

const axiosInstance = initialization(axiosRequestConfiguration);

export default axiosInstance;
